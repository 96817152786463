import '@hotwired/turbo-rails';
import { Application } from '@hotwired/stimulus';
import StimulusControllerResolver, {
  createViteGlobResolver,
} from '../javascripts/stimulus-controller-resolver';

import { StimulusInvokeController, StimulusInvokeElement } from 'stimulus-invoke';

const application = Application.start();

application.register('invoke', StimulusInvokeController);
StimulusInvokeElement.define();

StimulusControllerResolver.install(
  application,
  createViteGlobResolver(import.meta.glob('../../components/**/*.js'))
);
